import 'autotrack/lib/plugins/event-tracker';
import 'autotrack/lib/plugins/outbound-link-tracker';
import 'autotrack/lib/plugins/url-change-tracker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'normalize.css/normalize.css';
import pdfjs from '@bundled-es-modules/pdfjs-dist';
import { toast } from 'react-toastify';
import { reportError } from 'http/api';
import { APP_TITLE } from 'config/config';
import { loadFlags } from './flags';
import { decodeAccessToken, decodeImpersonationToken, getRelevantToken } from './util/token';
import setupTracking from './util/setupTracking';
import hotjar from './util/hotjar';
import './util/analytics';
import './styles/App.scss';

function removeSpinner () {
    const spinner = document.getElementById('spinner');
    spinner && document.body.removeChild(spinner);
}

function configureToastify () {
    toast.configure({
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        bodyClassName: 'Toastify__centered-text',
    });
}

function configurePdfjs () {
    const pdfjsWorkerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorkerSrc;
}

function setupLocalDebugUtils () {
    window.drdebug = {
        decodeAccessToken,
        decodeImpersonationToken,
        get id() {
            return this.decodeAccessToken().id;
        },
        get impId() {
            return this.decodeImpersonationToken().id;
        },
        copyActiveToken() {
            window.copy(getRelevantToken());
        },
        copyId() {
            window.copy(this.id);
        },
        copyImpId() {
            window.copy(this.impId);
        },
        copyQuery(nodeID = '') {
            const uid = this.impId || this.id;
            window.copy({ uid, id: nodeID });
        },
        yearReportData() {
            this.copyQuery('be92bqhakec7s91n3a8g');
        },
        docGenA() {
            this.copyQuery('bl1d66l7r9l3fo6am5ug');
        },
        docGenB() {
            this.copyQuery('bl4je51akec66d6otukg');
        },
        xbrlReport() {
            this.copyQuery('blt3k62a73ulq95v2rlg');
        },
        erpData() {
            this.copyQuery('bfc4nhhakec1m3953qc0');
        },
        checklist() {
            this.makeQuery('bpjoentd56jmta63c5qg');
        },
    };
}

const setAppTitle = () => {
    document.title = APP_TITLE;
};

const doLoadFlags = async () => {
    let remainingAttempts = 3;

    while (remainingAttempts > 0) {
        try {
            await loadFlags();
            break;
        } catch (e) {
            remainingAttempts--;
            reportError('failed to load feature flags', {
                reason: e.message,
            });
            await new Promise(resolve => setTimeout(resolve, 500));
        }
    }
};

async function onStartup () {
    setAppTitle();
    await doLoadFlags();
    removeSpinner();
    configureToastify();
    configurePdfjs();
    setupLocalDebugUtils();
    setupTracking();
    hotjar();
}

export default onStartup;