import { useState } from 'react';

const ObjectExplorer = ({ object, level = 0, prefix = null, levelsOpen = -1 }) => {
    const amountOfCharsUntilTruncation = 150;
    const [open, setOpen] = useState(levelsOpen >= level);
    const [maxStringSize, setMaxStringSize] = useState(amountOfCharsUntilTruncation);

    const close = () => setOpen(false);

    const doRender = () => {
        const kind = typeof object;

        const renderIndentedText = ({ value, onClick, hidePrefix, color, ...props }) => {
            return (
                <span
                    style={{
                        marginLeft: `${level * 16}px`,
                        cursor: onClick ? 'pointer' : 'inherit',
                    }}
                    onClick={onClick}
                    {...props}
                >
                    {!hidePrefix && prefix}<span style={{ color }}>{value}</span>
                </span>
            );
        };

        const renderLeaf = (color, value) => {
            return renderIndentedText({ color, value })
        };

        if (kind === 'string') {
            let toRender;
            if (object.length <= maxStringSize) {
                toRender = `"${object}"`;
            } else {
                toRender = (
                    <span
                        onClick={() => setMaxStringSize(maxStringSize + amountOfCharsUntilTruncation)}
                        style={{ cursor: 'pointer' }}
                    >
                        (truncated) "{object.slice(0, maxStringSize)}" ...
                    </span>
                );
            }

            return renderLeaf('red', toRender);
        }

        if (kind === 'number') {
            return renderLeaf('blue', object.toString());
        }

        if (kind === 'boolean') {
            return renderLeaf('blue', object.toString());
        }

        if (object === null) {
            return renderLeaf('gray', 'null');
        }

        if (object === undefined) {
            return renderLeaf('gray', 'undefined');
        }

        const renderClosed = (opener, closer, isEmpty) => {
            const dots = !isEmpty && <span style={{ opacity: 0.5 }}>...</span>;
            return renderIndentedText({
                value: <span>{opener}{dots}{closer}</span>,
                onClick: () => !isEmpty && setOpen(true),
            });
        };

        if (Array.isArray(object)) {
            if (!open) {
                return renderClosed('[', ']', object.length === 0);
            }

            return (
                <span>
                    {renderIndentedText({ value: '[', onClick: close })}
                    <br />
                    {object.map((thing, i) => {
                        const isLast = i === object.length - 1;
                        return (
                            <span>
                                <ObjectExplorer
                                    object={thing}
                                    level={level + 1}
                                    levelsOpen={level + 1}
                                />
                                {!isLast && <>{','}<br /></>}
                            </span>
                        );
                    })}
                    <br />
                    {renderIndentedText({ value: ']', onClick: close, hidePrefix: true })}
                </span>
            );
        }

        if (kind === 'object') {
            if (!open) {
                return renderClosed('{', '}', Object.keys(object).length === 0);
            }

            const entries = Object.entries(object);
            return (
                <span>
                    {renderIndentedText({ value: '{', onClick: close })}
                    <br />
                    {entries.map(([key, val], i) => {
                        const isLast = i === entries.length - 1;
                        return (
                            <span>
                                <ObjectExplorer
                                    object={val}
                                    level={level + 1}
                                    prefix={<span style={{ color: 'darkmagenta' }}>{key}:&nbsp;</span>}
                                    levelsOpen={levelsOpen}
                                />
                                {!isLast && <>{','}<br /></>}
                            </span>
                        );
                    })}
                    <br />
                    {renderIndentedText({ value: '}', onClick: close, hidePrefix: true })}
                </span>
            );
        }

        throw new Error('Unhandled type for ObjectExplorer: ' + kind);
    };

    return (
        <span
            children={doRender()}
            style={{ fontFamily: 'monospace' }}
        />
    );
};

export default ObjectExplorer;